/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.css"
import React from "react";

export const onClientEntry = () => {
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      window.gtag = gtag;
      window.gtag('js', new Date());
      window.gtag('config', 'G-WC4TDGSQ67');
    }
  };
  